import { useCallback, useMemo, useState, useEffect } from "react";
// import { s, subHours } from 'date-fns';
import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
// import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Card,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { useSelection } from "../hooks/use-selection";
import { OrdersTable } from "./orders-table";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
// import { data } from "./data";
import axios from "axios";

export function Orders() {
  const [allOrders, setAllOrders] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [filteredOrders, setFilteredOrders] = useState("");

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  function applyPagination(documents, page, rowsPerPage) {
    return documents.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }

  const handleOrderSearch = (val) => {
    setSearchVal(val);

    const filteredRows = allOrders.filter((order) => {
      return order._id.toLowerCase().includes(val.toLowerCase());
    });

    setFilteredOrders(filteredRows);
  };

  const useOrderIds = (orders) => {
    return useMemo(() => {
      return orders.map((order) => order._id);
    }, [orders]);
  };

  const useOrders = (page, rowsPerPage) => {
    return useMemo(() => {
      return applyPagination(allOrders, page, rowsPerPage);
    }, [page, rowsPerPage, allOrders]);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const orders = useOrders(page, rowsPerPage);
  const ordersIds = useOrderIds(orders);
  const ordersSelection = useSelection(ordersIds);

  // const BASE_URL = "http://localhost:3800";

  const BASE_URL = "https://nft-ecom-backend.vercel.app";

  async function fetchData() {
    try {
      const Url = BASE_URL + "/order/";

      const response = await axios.get(Url);

      console.log(response.data.data);
      setAllOrders(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 6,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Orders</Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Import
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Export
                  </Button>
                </Stack>
              </Stack>
              {/* <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Add
                </Button>
              </div> */}
            </Stack>
            <Card sx={{ p: 2 }}>
              <OutlinedInput
                defaultValue=""
                fullWidth
                placeholder="Enter Order Id"
                startAdornment={
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <MagnifyingGlassIcon />
                    </SvgIcon>
                  </InputAdornment>
                }
                onChange={(e) => handleOrderSearch(e.target.value)}
                sx={{ maxWidth: 500 }}
              />
            </Card>
            <OrdersTable
              count={allOrders.length}
              items={searchVal === "" ? orders : filteredOrders}
              onDeselectAll={ordersSelection.handleDeselectAll}
              onDeselectOne={ordersSelection.handleDeselectOne}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              onSelectAll={ordersSelection.handleSelectAll}
              onSelectOne={ordersSelection.handleSelectOne}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={ordersSelection.selected}
            />
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Orders;
