import React, { useState } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [emailId, setEmailId] = useState("demo@NFT.com");
  const [password, setPassword] = useState("Password123!");
  // const [emailId, setEmailId] = useState("admin@nft.ecom.in");
  // const [password, setPassword] = useState("Admin123!");
  const navigate = useNavigate();

  const handleLogin = () => {
    if (emailId === "admin@nft.ecom.in" && password === "Admin123!") {
      localStorage.setItem("x-auth-token", "admin-access-allowed");
      setTimeout(() => {
        navigate("/products");
        navigate(0);
      }, 1000);
    } else {
      toast.error("Invalid EmailId and Password");
      return;
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "140px" }}>
      <Card elevation={3}>
        <CardHeader title="Log in" />
        <CardContent>
          <div>
            <TextField
              fullWidth
              label="Email Address"
              id="email"
              name="email"
              type="email"
              placeholder=""
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
            />
          </div>

          <div>
            <TextField
              fullWidth
              label="Password"
              id="password"
              name="password"
              type="password"
              value={password}
              sx={{ mt: 2 }}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            sx={{ mt: 2 }}
            onClick={handleLogin}
          >
            Log In
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
