// import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import ShoppingBagIcon from "@heroicons/react/24/solid/ShoppingBagIcon";
// import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import AdjustmentsHorizontalIcon from "@heroicons/react/24/solid/AdjustmentsHorizontalIcon";
import { SvgIcon } from "@mui/material";

export const items = [
  // {
  //   title: "Dahboard",
  //   path: "/",
  //   icon: (
  //     <SvgIcon fontSize="small">
  //       <ChartBarIcon />
  //     </SvgIcon>
  //   ),
  // },
  {
    title: "Products",
    path: "/products",
    icon: (
      <SvgIcon fontSize="small">
        <ArchiveBoxIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Orders",
    path: "/orders",
    icon: (
      <SvgIcon fontSize="small">
        <ShoppingBagIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Configs",
    path: "/configs",
    icon: (
      <SvgIcon fontSize="small">
        <AdjustmentsHorizontalIcon />
      </SvgIcon>
    ),
  },
  // {
  //   title: "Users",
  //   path: "/users",
  //   icon: (
  //     <SvgIcon fontSize="small">
  //       <UsersIcon />
  //     </SvgIcon>
  //   ),
  // },
];
