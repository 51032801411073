import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "./theme/index";
import {
  ThirdwebProvider,
  metamaskWallet,
  walletConnect,
  paperWallet,
} from "@thirdweb-dev/react";
import { Mumbai, Polygon } from "@thirdweb-dev/chains";

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme();

root.render(
  <React.StrictMode>
    <ThirdwebProvider
      // activeChain={Mumbai}
      supportedChains={[Mumbai, Polygon]}
      clientId="7de394ce6fd45a1f2c116d50083657b0"
      supportedWallets={[
        metamaskWallet(),
        walletConnect(),
        paperWallet({
          paperClientId: "7c7e2c81-5c90-4777-bd17-b87a5fbb36de",
        }),
      ]}
    >
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ThirdwebProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
