import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  FormLabel,
} from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import { SeverityPill } from "../Common/severity-pill";
import axios from "axios";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

function OrderDetails() {
  // const BASE_URL = "http://localhost:3800";

  const BASE_URL = "https://nft-ecom-backend.vercel.app";

  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState({});
  const [editClick, setEditClick] = useState(false);
  const [newOrderStatus, setNewOrderStatus] = useState();

  const statusMap = {
    PROCESSING: "warning",
    SHIPPED: "info",
    DELIVERED: "success",
    NEW: "primary",
    CANCELLED: "error",
  };

  const paymentStatusMap = {
    SUCCESS: "success",
    PENDING: "info",
    CANCELLED: "error",
  };

  const editClicked = () => {
    setEditClick(!editClick);
  };

  const updateOrder = () => {
    if (newOrderStatus !== orderDetails.orderStatus) {
      let newOrderDetails = { orderStatus: newOrderStatus };
      updateOrderById(newOrderDetails);
    }
    editClicked();
  };

  async function updateOrderById(newOrderInfo) {
    const id = toast.loading("Processing...");
    try {
      const Url = BASE_URL + `/order/${orderId}`;
      const response = await axios.post(Url, { newOrderInfo: newOrderInfo });
      const order = response.data.data;
      console.log(order, "orders");
      setOrderDetails(order);
      toast.update(id, {
        render: "Order Updated successfully..",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
      });
    } catch (error) {
      toast.update(id, {
        render: "Error Updating your Order",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
      });
      setNewOrderStatus(orderDetails.orderStatus);
      console.error("Error:", error);
    }
  }

  async function fetchData() {
    try {
      const Url = BASE_URL + `/order/orderId/${orderId}`;

      const response = await axios.get(Url);
      const value = response.data.data;
      console.log(value, "orders");
      setOrderDetails(value);
      setNewOrderStatus(value.orderStatus);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  console.log(orderDetails);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          width: "100%",
          maxWidth: "900px",
          marginLeft: "20px",
          marginBottom: "50px",
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Details</Typography>
            {!editClick && (
              <Button
                variant="text"
                size="small"
                startIcon={<EditIcon />}
                onClick={editClicked}
              >
                Edit
              </Button>
            )}
          </Stack>
          <List>
            <ListItem divider>
              <ListItemText>
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ minWidth: "200px", fontWeight: 600 }}
                  >
                    Order ID
                  </Typography>
                  <Box>
                    <Typography variant="body2">{orderDetails._id}</Typography>
                  </Box>
                </Stack>
              </ListItemText>
            </ListItem>

            <ListItem divider>
              <ListItemText>
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ minWidth: "200px", fontWeight: 600 }}
                  >
                    Customer Info
                  </Typography>
                  <Box>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Name: </span>
                      {orderDetails?.customerInfo?.name}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Email: </span>
                      {orderDetails?.customerInfo?.email}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Phone No: </span>
                      {orderDetails?.customerInfo?.phoneNo}
                    </Typography>
                  </Box>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider>
              <ListItemText>
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ minWidth: "200px", fontWeight: 600 }}
                  >
                    Shipping Address
                  </Typography>
                  <Box>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>House No: </span>
                      {orderDetails?.customerInfo?.shippingAddress?.houseNo}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Street Address: </span>
                      {
                        orderDetails?.customerInfo?.shippingAddress
                          ?.streetAddress
                      }
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Province: </span>
                      {orderDetails?.customerInfo?.shippingAddress?.province}
                    </Typography>

                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Town: </span>
                      {orderDetails?.customerInfo?.shippingAddress?.town}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>ZIP: </span>
                      {orderDetails?.customerInfo?.shippingAddress?.zip}
                    </Typography>
                  </Box>
                </Stack>
              </ListItemText>
            </ListItem>
            <ListItem divider>
              <ListItemText>
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ minWidth: "200px", fontWeight: 600 }}
                  >
                    Payment Details
                  </Typography>
                  <Box>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Payment Method: </span>
                      {orderDetails?.paymentInfo?.paymentMethod}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Payment Status: </span>
                      <SeverityPill
                        color={
                          paymentStatusMap[
                            orderDetails?.paymentInfo?.paymentStatus
                          ]
                        }
                      >
                        {orderDetails?.paymentInfo?.paymentStatus}
                      </SeverityPill>
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Transaction Id: </span>
                      {orderDetails?.paymentInfo?.paymentMethod === "ETH"
                        ? orderDetails?.paymentInfo?.blockchainTransactionHash
                        : orderDetails?.paymentInfo?.transactionId}
                    </Typography>
                  </Box>
                </Stack>
              </ListItemText>
            </ListItem>

            <ListItem divider>
              <ListItemText>
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ minWidth: "200px", fontWeight: 600 }}
                  >
                    Shipping Details
                  </Typography>
                  <Box>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>Shipping Method: </span>
                      {orderDetails?.shippingDetails?.shippingMethod}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 500 }}>
                        Estimated Delivery Date:{" "}
                      </span>

                      {orderDetails &&
                        orderDetails.shippingDetails &&
                        format(
                          new Date(
                            orderDetails?.shippingDetails?.estimateDeliveryDate
                          ),
                          "dd-MM-yyyy"
                        )}
                    </Typography>
                  </Box>
                </Stack>
              </ListItemText>
            </ListItem>

            <ListItem divider>
              <ListItemText>
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ minWidth: "200px", fontWeight: 600 }}
                  >
                    Order Date
                  </Typography>
                  <Box>
                    <Typography variant="body2">
                      {orderDetails &&
                        orderDetails.createdAt &&
                        format(new Date(orderDetails?.createdAt), "dd-MM-yyyy")}
                    </Typography>
                  </Box>
                </Stack>
              </ListItemText>
            </ListItem>

            <ListItem divider>
              <ListItemText>
                <Stack direction="row">
                  <Typography
                    variant="subtitle1"
                    style={{ minWidth: "200px", fontWeight: 600 }}
                  >
                    Order Status
                  </Typography>
                  {!editClick && (
                    <Box>
                      <Typography variant="body2">
                        <SeverityPill
                          color={statusMap[orderDetails?.orderStatus]}
                        >
                          {orderDetails?.orderStatus}
                        </SeverityPill>
                      </Typography>
                    </Box>
                  )}

                  {editClick && (
                    <FormControl fullWidth>
                      <FormLabel
                        htmlFor="newOrderStatus"
                        sx={{ marginLeft: "4px", marginBottom: "10px" }}
                      >
                        New Order Status
                      </FormLabel>
                      <Select
                        id="newOrderStatus"
                        value={newOrderStatus}
                        onChange={(e) => setNewOrderStatus(e.target.value)}
                      >
                        <MenuItem value={"IN PROGRESS"}>IN PROGRESS</MenuItem>
                        <MenuItem value={"SHIPPED"}>SHIPPED</MenuItem>
                        <MenuItem value={"DELIVERED"}>DELIVERED</MenuItem>
                        <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </ListItemText>
            </ListItem>
          </List>
        </Stack>

        <Box style={{ marginTop: "40px" }}>
          <Typography variant="h6">Order items</Typography>
          <TableContainer component={Paper} sx={{ marginTop: "30px" }}>
            <Table aria-label="Line Items Table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>NFT Token Id</TableCell>
                  <TableCell>Product Number</TableCell>
                  <TableCell>Product Color</TableCell>
                  <TableCell>Product Size</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderDetails?.orderDetails?.productDetails.map((product) => (
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {product?.productName}
                      </Typography>
                    </TableCell>

                    <TableCell>{product?.tokenId}</TableCell>
                    <TableCell> {product?.productNumber}</TableCell>
                    <TableCell> {product?.color}</TableCell>
                    <TableCell> {product?.size}</TableCell>
                    <TableCell> {product?.quantity}</TableCell>
                    <TableCell>${product?.price}</TableCell>
                  </TableRow>
                ))}

                <div>
                  <ListItem divider>
                    <ListItemText>
                      <Stack direction="row">
                        <Typography
                          variant="subtitle2"
                          style={{ minWidth: "200px" }}
                        >
                          Sub Total
                        </Typography>
                        <Box>
                          <Typography variant="body2">
                            ${orderDetails?.orderDetails?.subTotal}
                          </Typography>
                        </Box>
                      </Stack>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider>
                    <ListItemText>
                      <Stack direction="row">
                        <Typography
                          variant="subtitle2"
                          style={{ minWidth: "200px" }}
                        >
                          Shipping Charge
                        </Typography>
                        <Box>
                          <Typography variant="body2">
                            ${orderDetails?.orderDetails?.shippingCharge}
                          </Typography>
                        </Box>
                      </Stack>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider>
                    <ListItemText>
                      <Stack direction="row">
                        <Typography
                          variant="subtitle2"
                          style={{ minWidth: "200px" }}
                        >
                          Tax
                        </Typography>
                        <Box>
                          <Typography variant="body2">
                            ${orderDetails?.orderDetails?.tax}
                          </Typography>
                        </Box>
                      </Stack>
                    </ListItemText>
                  </ListItem>
                  <ListItem divider>
                    <ListItemText>
                      <Stack direction="row">
                        <Typography
                          variant="subtitle2"
                          style={{ minWidth: "200px" }}
                        >
                          Total Amount
                        </Typography>
                        <Box>
                          <Typography variant="body2">
                            $
                            {orderDetails?.orderDetails?.totalAmount.toFixed(4)}
                          </Typography>
                        </Box>
                      </Stack>
                    </ListItemText>
                  </ListItem>
                </div>
              </TableBody>
            </Table>
          </TableContainer>

          {editClick && (
            <Stack style={{ marginTop: "20px" }} direction="row" spacing={2}>
              <Button variant="outlined" onClick={editClicked}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={updateOrder}>
                Update
              </Button>
            </Stack>
          )}
        </Box>
      </Paper>
    </>
  );
}

export default OrderDetails;
