import {
  Box,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { SeverityPill } from "../Common/severity-pill";
import { Link } from "react-router-dom";

export const OrdersTable = (props) => {
  const {
    count = 0,
    items = [],
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => {},
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    page = 0,
    rowsPerPage = 0,
    selected = [],
  } = props;

  const statusMap = {
    PROCESSING: "warning",
    SHIPPED: "info",
    DELIVERED: "success",
    CANCELLED: "error",
    NEW: "primary",
  };

  const selectedSome = selected.length > 0 && selected.length < items.length;
  const selectedAll = items.length > 0 && selected.length === items.length;

  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedAll}
                  indeterminate={selectedSome}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onSelectAll?.();
                    } else {
                      onDeselectAll?.();
                    }
                  }}
                />
              </TableCell>
              <TableCell>Order Id</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((order) => {
              const isSelected = selected.includes(order._id);
              const createdAt = format(new Date(order.createdAt), "dd-MM-yyyy");

              return (
                <TableRow hover key={order._id} selected={isSelected}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onSelectOne?.(order._id);
                        } else {
                          onDeselectOne?.(order._id);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack alignItems="center" direction="row" spacing={2}>
                      <Link
                        to={`/order/${order._id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography variant="subtitle2">{order._id}</Typography>
                      </Link>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {order?.paymentInfo?.paymentMethod
                      ? order?.paymentInfo?.paymentMethod
                      : "None"}
                  </TableCell>
                  <TableCell>
                    {order.orderDetails.totalAmount.toFixed(4)}
                  </TableCell>
                  <TableCell>
                    <SeverityPill color={statusMap[order.orderStatus]}>
                      {order.orderStatus}
                    </SeverityPill>
                  </TableCell>
                  <TableCell>{createdAt}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};
