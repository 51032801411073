import { useEffect, useState } from "react";
import { Layout } from "./components/navbar/layout";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Users from "./components/users/users";
import Orders from "./components/orders/orders";
import OrderDetails from "./components/orders/order-details";
import Products from "./components/Product/Products";
import ProductEntry from "./components/Product/product-entry";
import ProductEdit from "./components/Product/product-edit";
import Login from "./components/Auth/Login";
import NotFound from "./components/Auth/NotFound";
import { ToastContainer } from "react-toastify";
import Config from "./components/Common/config";
import "react-toastify/dist/ReactToastify.css";

const authUser = () => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem("x-auth-token")) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const fetchData = async () => {
    const val = await authUser();
    if (val) {
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        {isAuthenticated ? (
          <Routes>
            <Route path="/" element={<Navigate to="/products" replace />} />
            <Route
              exact
              path="/products"
              element={
                <Layout>
                  <Products />
                </Layout>
              }
            />
            <Route
              exact
              path="/productAdd"
              element={
                <Layout>
                  <ProductEntry />
                </Layout>
              }
            />
            <Route
              exact
              path="/product/:productId"
              element={
                <Layout>
                  <ProductEdit />
                </Layout>
              }
            />
            <Route
              exact
              path="/users"
              element={
                <Layout>
                  <Users />
                </Layout>
              }
            />
            <Route
              exact
              path="/orders"
              element={
                <Layout>
                  <Orders />
                </Layout>
              }
            />
            <Route
              exact
              path="/order/:orderId"
              element={
                <Layout>
                  <OrderDetails />
                </Layout>
              }
            />
            <Route
              exact
              path="/configs"
              element={
                <Layout>
                  <Config />
                </Layout>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Login />
        )}
      </Router>
    </div>
  );
}

export default App;
