import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD13Lbrg8LXJQCtxyKMEI64JXbTTKMavXM",
  authDomain: "nft-ecom.firebaseapp.com",
  projectId: "nft-ecom",
  storageBucket: "nft-ecom.appspot.com",
  messagingSenderId: "111287135757",
  appId: "1:111287135757:web:9f19b22508257cb9d0ef44",
  measurementId: "G-FDZJ3N351M",
};

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export { storage };
