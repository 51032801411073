import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

function Config() {
  //   const BaseURL = "http://localhost:3800";

  const BaseURL = "https://nft-ecom-backend.vercel.app";

  const [totalNumProducts, setTotalNumProducts] = useState(0);
  const [productsLeft, setProductsLeft] = useState(0);
  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    axios
      .get(`${BaseURL}/config`)
      .then(function (response) {
        console.log(response.data.data);
        setProductsLeft(response.data.data.productsLeft);
        setTotalNumProducts(response.data.data.noOfProducts);
      })
      .catch(function (error) {
        // Handle any errors
        console.log(error);
      });
  }, []);

  const handleSave = async () => {
    const url = `${BaseURL}/config/update`;
    try {
      const obj = {
        noOfProducts: totalNumProducts,
        productsLeft: productsLeft,
      };

      const response = await axios.post(url, obj);
      console.log(response);

      if (response.data.status === 200) {
        toast.success("Config Updated successfully..", {
          toastId: "config",
        });
        setEditMode(false);
      } else {
        toast.error("Error Occured", {
          toastId: "config",
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 6,
        }}
      >
        <Container maxWidth="md">
          <Stack>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Configs</Typography>
              </Stack>
            </Stack>

            <Stack sx={{ marginTop: "50px" }}>
              <TextField
                name="total no of prouducts"
                label="Total no of prouducts"
                fullWidth
                value={totalNumProducts}
                style={{ margin: "10px" }}
                required
                disabled={!isEditMode}
                onChange={(e) => setTotalNumProducts(e.target.value)}
              />

              <TextField
                name="prouducts left"
                label="Prouducts Left"
                fullWidth
                value={productsLeft}
                style={{ margin: "10px" }}
                required
                disabled={!isEditMode}
                onChange={(e) => setProductsLeft(e.target.value)}
              />
            </Stack>

            <Stack direction="row" spacing={4} sx={{ mt: 2, ml: 1 }}>
              {!isEditMode && (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  onClick={(e) => {
                    setEditMode(true);
                  }}
                  sx={{ maxWidth: "120px" }}
                >
                  Edit
                </Button>
              )}

              {isEditMode && (
                <>
                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    size="large"
                    type="submit"
                    onClick={(e) => {
                      setEditMode(false);
                    }}
                    sx={{ maxWidth: "120px" }}
                  >
                    Cancel
                  </Button>

                  <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    size="large"
                    type="submit"
                    onClick={handleSave}
                    sx={{ maxWidth: "120px" }}
                  >
                    Save
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        </Container>
      </Box>
    </>
  );
}

export default Config;
