import React, { useState, useRef } from "react";
import {
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Select,
  Button,
  OutlinedInput,
  List,
  ListItem,
  ListItemText,
  Avatar,
  IconButton,
  MenuItem,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Paper
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../Common/firebase";
import CircularProgressWithLabel from "../Common/progress-bar";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProductEntry = () => {
  const navigate = useNavigate();
  // const BASE_URL = "http://localhost:3800";

  const BASE_URL = "https://nft-ecom-backend.vercel.app";

  const fileInputRef = useRef(null);
  const descImgInputRef = useRef(null);

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [selectedSizes, setSelectedSizes] = useState({});
  const [sizeQuantities, setSizeQuantities] = useState({});
  const [sku, setSku] = useState("SKU");
  const [category, setCategory] = useState("Hoody");
  const [productDisplayImageFile, setProductDisplayImageFile] = useState();
  const [isUploadDisabled, disableUpload] = useState(false);
  const [displayImageProg, setDisplayImageProg] = useState({});
  const [displayImgUrl, setDisplayImgUrl] = useState("");
  const [tokenId, setTokenId] = useState("");

  const [productDescriptionImageFiles, setproductDescriptionImageFiles] =
    useState([]);

  const [productDescImageUrl, setProductDescImageUrl] = useState([]);
  const [isDescImgUploadDisabled, disableDescImgUpload] = useState(false);
  const [descImgProg, setDescImageProg] = useState({});

  const handleDescImageChange = (url) => {
    setProductDescImageUrl((prevUrls) => [...prevUrls, url]);
  };

  const handleImageChange = (e) => {
    const selectedImages = e.target.files;
    setproductDescriptionImageFiles([...selectedImages]);
  };

  const handleImageRemove = (index) => {
    const newImages = [...productDescriptionImageFiles];
    newImages.splice(index, 1);
    setproductDescriptionImageFiles(newImages);
  };

  const handleProductCreate = async () => {
    if (
      !productName ||
      !productDescription ||
      !price ||
      !color ||
      !category ||
      !sku ||
      !sizeQuantities ||
      !displayImgUrl ||
      !productDescImageUrl ||
      !tokenId
    ) {
      toast.info("Fill all the values..", {
        toastId: "Product Create Check"
      });
      return;
    }

    if (productDescImageUrl.length !== 4) {
      console.log("Desc img not 4");
      return;
    }

    const sizeQuantityMapping = Object.keys(sizeQuantities).map((size) => {
      return {
        size: size,
        quantity: sizeQuantities[size],
        quantitySold: []
      };
    });

    const obj = {
      productName: productName,
      productDescription: productDescription,
      price: price,
      color: color,
      category: category,
      sku: sku,
      productDisplayImage: displayImgUrl,
      sizeQuantityMapping: sizeQuantityMapping,
      productDescriptionImage: productDescImageUrl,
      tokenId: tokenId
    };

    const url = BASE_URL + "/product/add/new";

    try {
      console.log(obj);
      const response = await axios.post(url, obj);

      console.log("Product Add Resp", response.data);

      if (response.data.status === 200) {
        toast.success("Product added successfully..", {
          toastId: "ProductAdd"
        });

        setTimeout(() => {
          navigate("/products");
        }, 2000);
      } else {
        toast.error("Some Error Occured", {
          toastId: "ProductAddError"
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const generateSKU = () => {
    const sku = ("NFT-" + category.substring(0, 2) + "-" + color).toUpperCase();
    setSku(sku);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    // Update the selectedSizes object based on the checkbox state
    if (checked) {
      setSelectedSizes((prevSelectedSizes) => ({
        ...prevSelectedSizes,
        [value]: true
      }));

      setSizeQuantities((prevSizeQuantities) => ({
        ...prevSizeQuantities,
        [value]: prevSizeQuantities[value] || 0
      }));
    } else {
      // Remove the size from selectedSizes when checkbox is unchecked
      setSelectedSizes((prevSelectedSizes) => {
        const newSelectedSizes = { ...prevSelectedSizes };
        delete newSelectedSizes[value];
        return newSelectedSizes;
      });

      setSizeQuantities((prevSizeQuantities) => {
        const newSizeQuantities = { ...prevSizeQuantities };
        delete newSizeQuantities[value];
        return newSizeQuantities;
      });
    }
  };

  const handleQuantityChange = (event, size) => {
    const newQuantity = parseInt(event.target.value, 10) || 0;
    setSizeQuantities((prevSizeQuantities) => ({
      ...prevSizeQuantities,
      [size]: newQuantity
    }));
  };

  const handleDescriptionInputClick = () => {
    descImgInputRef.current.click();
  };
  const handleSelectFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (file) => {
    setProductDisplayImageFile(file);
  };

  const uploadFile = () => {
    if (!productDisplayImageFile) {
      return;
    }

    disableUpload(true);
    setDisplayImageProg({ show: true, progress: 0 });

    const fileRef = ref(storage, `images/${productDisplayImageFile.name}`);

    const uploadTask = uploadBytesResumable(fileRef, productDisplayImageFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setDisplayImageProg({ show: true, progress: progress });
        console.log(progress);
      },
      (error) => {
        console.log(error);
        disableUpload(false);
        setDisplayImageProg({ show: false, progress: 0 });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setDisplayImgUrl(downloadURL);
        });

        disableUpload(false);
        setDisplayImageProg({ show: false, progress: 0 });
      }
    );
  };

  const uploadDescriptionFile = async () => {
    if (productDescriptionImageFiles.length !== 4) {
      toast.error("Select 4 images", {
        toastId: "Incomplete images"
      });
      return;
    }

    disableDescImgUpload(true);

    try {
      for (let i = 0; i < productDescriptionImageFiles.length; i++) {
        const imageFile = productDescriptionImageFiles[i];
        const fileRef = ref(storage, `images/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(fileRef, imageFile);

        setDescImageProg({ show: true, progress: 0 });

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setDescImageProg({ show: true, progress: progress });
            console.log(progress);
          },
          (error) => {
            console.log(error);
            disableDescImgUpload(false);
            setDescImageProg({ show: false, progress: 0 });
          }
        );

        await uploadTask;
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log("File available at", downloadURL);
        handleDescImageChange(downloadURL);
      }
    } catch (error) {
      console.error(error);
    } finally {
      disableDescImgUpload(false);
      setDescImageProg({ show: false, progress: 0 });
    }
  };

  return (
    <Container maxWidth="xl" style={{ marginBottom: "40px" }}>
      <Stack spacing={3}>
        <Stack>
          <Typography variant="h4">Create a new product</Typography>
        </Stack>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Basic details</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      id="productName"
                      name="productName"
                      type="text"
                      label="Product Name"
                      fullWidth
                      sx={{ marginTop: "8px" }}
                      required
                      onChange={(e) => setProductName(e.target.value)}
                    />
                  </FormControl>
                  {/* <Typography variant="subtitle2">Description</Typography> */}
                  {/* Quill Editor can be integrated here */}

                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Description"
                      multiline
                      rows={8}
                      maxRows={20}
                      required
                      onChange={(e) => setProductDescription(e.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      id="tokenId"
                      name="tokenId"
                      type="text"
                      label="Token Id"
                      fullWidth
                      sx={{ marginTop: "8px" }}
                      required
                      onChange={(e) => setTokenId(e.target.value)}
                    />
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Images</Typography>
                <Typography variant="body2">
                  Images will appear in the store front of your website.
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack
                  alignItems="center"
                  spacing={2}
                  style={{
                    border: "1px dashed rgb(242, 244, 247)",
                    borderRadius: "8px",
                    padding: "50px"
                  }}
                >
                  <div
                    className="MuiBox-root css-oitt7o"
                    role="presentation"
                    tabIndex="0"
                  >
                    <input
                      accept="image/*"
                      multiple={false}
                      type="file"
                      tabIndex="-1"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => handleFileChange(e.target.files[0])}
                    />
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      onClick={handleSelectFileClick}
                      sx={{ cursor: "pointer" }}
                    >
                      <Avatar className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-1x8tnu7">
                        <CloudUploadIcon />
                      </Avatar>
                      <Stack>
                        <Typography
                          variant="h6"
                          className="MuiTypography-root MuiTypography-h6 css-1rb0bry"
                        >
                          <span>Click to upload</span>
                        </Typography>
                        <Typography
                          variant="body2"
                          className="MuiTypography-root MuiTypography-body2 css-1968ntf"
                        >
                          (SVG, JPG, PNG, or GIF)
                        </Typography>
                      </Stack>
                    </Stack>
                  </div>
                </Stack>

                <Box sx={{ marginTop: "20px" }}>
                  {productDisplayImageFile && (
                    <List
                      style={{
                        border: "1px solid rgb(242, 244, 247)",
                        borderRadius: "8px",
                        padding: "16px"
                      }}
                    >
                      <ListItem>
                        <ListItemText>
                          <Typography variant="subtitle2">
                            {productDisplayImageFile.name}
                          </Typography>
                        </ListItemText>

                        <IconButton
                          aria-label="Remove"
                          onClick={(e) => {
                            setProductDisplayImageFile(null);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    </List>
                  )}

                  <Stack
                    direction="row"
                    sx={{ marginTop: "20px", justifyContent: "flex-end" }}
                  >
                    {displayImageProg.show && (
                      <CircularProgressWithLabel
                        value={displayImageProg.progress}
                      />
                    )}

                    {productDisplayImageFile && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={uploadFile}
                        disabled={isUploadDisabled}
                        sx={{ marginLeft: "20px" }}
                      >
                        Upload
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Images</Typography>
                <Typography variant="body2">
                  Images will appear in the description of your website.
                </Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack
                  alignItems="center"
                  spacing={2}
                  style={{
                    border: "1px dashed rgb(242, 244, 247)",
                    borderRadius: "8px",
                    padding: "50px"
                  }}
                >
                  <div
                    className="MuiBox-root css-oitt7o"
                    role="presentation"
                    tabIndex="0"
                  >
                    <input
                      accept="image/*"
                      multiple={true}
                      type="file"
                      tabIndex="-1"
                      style={{ display: "none" }}
                      ref={descImgInputRef}
                      onChange={handleImageChange}
                    />
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{ cursor: "pointer" }}
                      onClick={handleDescriptionInputClick}
                    >
                      <Avatar className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault css-1x8tnu7">
                        <CloudUploadIcon />
                      </Avatar>
                      <Stack>
                        <Typography
                          variant="h6"
                          className="MuiTypography-root MuiTypography-h6 css-1rb0bry"
                        >
                          <span>Click to upload</span>
                        </Typography>
                        <Typography
                          variant="body2"
                          className="MuiTypography-root MuiTypography-body2 css-1968ntf"
                        >
                          (SVG, JPG, PNG, or GIF)
                        </Typography>
                      </Stack>
                    </Stack>
                  </div>
                </Stack>

                <Box sx={{ marginTop: "20px" }}>
                  {productDescriptionImageFiles &&
                    productDescriptionImageFiles.map((file, idx) => (
                      <List
                        key={idx}
                        style={{
                          border: "1px solid rgb(242, 244, 247)",
                          borderRadius: "8px",
                          padding: "16px"
                        }}
                      >
                        <ListItem>
                          <ListItemText>
                            <Typography variant="subtitle2">
                              {file.name}
                            </Typography>
                          </ListItemText>

                          <IconButton
                            aria-label="Remove"
                            onClick={(e) => {
                              handleImageRemove(idx);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItem>
                      </List>
                    ))}

                  <Stack
                    direction="row"
                    sx={{ marginTop: "20px", justifyContent: "flex-end" }}
                  >
                    {descImgProg.show && (
                      <CircularProgressWithLabel value={descImgProg.progress} />
                    )}

                    {productDescriptionImageFiles.length > 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={uploadDescriptionFile}
                        disabled={isDescImgUploadDisabled}
                        sx={{ marginLeft: "20px" }}
                      >
                        Upload
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Other Details</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      id="price"
                      name="price"
                      label="Price"
                      fullWidth
                      style={{ marginTop: "8px" }}
                      required
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      label="Color"
                      name="color"
                      type="text"
                      style={{ marginTop: "6px" }}
                      required
                      onChange={(e) => setColor(e.target.value)}
                    />
                  </FormControl>

                  <div className="mb-20 mb-sm-15">
                    <div
                      className="single-atr"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                    >
                      <Typography variant="subtitle1" className="bold">
                        Size
                      </Typography>
                      <FormGroup
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: "50px"
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="S"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="S"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="M"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="M"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="L"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="L"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="XL"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="XL"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="XXL"
                              onChange={handleCheckboxChange}
                            />
                          }
                          label="XXL"
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="table-wrapper mb-20 mb-sm-15">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Sizes</TableCell>
                            <TableCell>Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(selectedSizes).map((size) => (
                            <TableRow key={size}>
                              <TableCell>
                                <Typography variant="subtitle1">
                                  {size}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <TextField
                                  type="text"
                                  step="any"
                                  label="Quantity"
                                  value={String(sizeQuantities[size]) || ""}
                                  onChange={(event) =>
                                    handleQuantityChange(event, size)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">Category</Typography>
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor="category" sx={{ marginLeft: "4px" }}>
                      Category
                    </FormLabel>

                    <Select
                      id="category"
                      name="category"
                      value={category}
                      fullWidth
                      style={{ marginTop: "6px" }}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <MenuItem value="Hoody">Hoody</MenuItem>
                      <MenuItem value="Kimono">Kimono</MenuItem>
                      <MenuItem value="Bag">Bag</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <FormLabel htmlFor="sku" sx={{ marginLeft: "4px" }}>
                      SKU
                    </FormLabel>

                    <div style={{ display: "flex" }}>
                      <OutlinedInput
                        id="sku"
                        name="sku"
                        type="text"
                        fullWidth
                        disabled
                        style={{ marginTop: "6px" }}
                        value={sku}
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={generateSKU}
                        sx={{
                          marginLeft: "24px",
                          marginTop: "4px",
                          marginBottom: "4px"
                        }}
                      >
                        Generate
                      </Button>
                    </div>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={(e) => navigate("/products")}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProductCreate}
          >
            Create
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ProductEntry;
